export const translations = {
  en: {
    smileyFaceRating: {
      ratingInfo: 'We in Feedbach love feedback, and we would really appreciate what you think about our solution to collect feedback. Please rate us with a smiley face, and let us know what you think.',
      receipt: 'Thank you for your feedback!',
    },
    join: {
      aboutTo: 'You are about to join the organization {1}',
      join: 'Join {1}',
      orgFetchError: 'Could not fetch organization data. Please try again or contact support.',
      joinFailed: 'Could not join the organization. Please try again or contact support.',
    },
    misc: {
      serverError: 'An error occured. Please try again or contact hei@feedbach.me',
      languageSelectorLabel: 'Language',
      copyToClipboard: 'Copy',
      copiedToClipboard: 'Copied to clipboard',
      errorCopying: 'Copying to clipboaard failed',
    },
    subscription: {
      activateSub: 'Activate subscription',
      subscriptionOrderText: 'Start a new 12-month period with evaluations and continuous improvement',
      subscriptionOrderInfo: 'You will be sent to Stripe to complete the payment.',
      goToCheckout: 'Go to checkout',
      subscriptionActivatedHeading: 'Subscription activated',
      settingsHeading: 'Subscription',
      detailsHeading: 'Subscription Details',
      startDateLabel: 'Start date',
      endDateLabel: 'End date',
      subscriptionStatus: 'Status',
      status_active: 'Active',
      status_none: 'No active subscription',
      status_ended: 'Expored',
      status_created: 'Created',
      openPortal: 'Manage subscription',
      subscriptionActivatedSuccess: 'The subscription is now activated',
      subscriptionActivationError: 'There was an error when we tried to activate the subscription. Please try again, or contact support.',
      checkoutCancelled: 'The checkout was cancelled. Please activate a subscription in order to continue using Feedbach.',
    },
    orgDash: {
      scoreHeading: 'Organization Score',
      surveyTypeHeading_delivery: 'Survey Type - Service Delivery',
      "surveyTypeHeading_long-term": 'Survey Type - Long Term Assignment',
      surveyTypeHeading_workshop: 'Survey Type - Workshop or Course',
      orgScoreInfo: 'These values show the score across all of the organizations surveys, not including private surveys.',
      periodForever: 'Since the start',
      periodYear: 'Last 12 month',
      period6m: 'Last 6 months',
      period3m: 'Last 3 months',
      periodLabel: 'Select period',
      periodInterval: 'Choose from and to dates',
      answersLabel: 'Answers',
      surveyLabel: 'Surveys',
      updateBtn: 'Update',
      toDate: 'To',
      fromDate: 'From',
      candidatePlaceholder: '[name of consultant]',
      departmentLabel: 'Department',
    },
    comments: {
      addCommentHeading: 'Add comment',
      fieldRequired: 'Can not post empty comment',
      serverError: 'We could not save your comment. Please try again or contact support.',
      submit: 'Save comment',
    },
    improvementGoals: {
      noSelectedCategories: 'No categories selected',
      noSharedGoals: 'There are no improvement goals that has been shared with you yet.',
      noGoals: 'You have not created any improvement goals yet. Start with a survey and set an improvement goal based on the results from it.',
      editOrgHeading: 'Edit Organization Goal',
      orgHeadingCreate: 'Create an Organization Goal',
      create: 'Create improvement goal',
      missingOriginalSurvey: 'The goal needs to be created from a survey. Start form from a completed survey and try again.',
      fieldRequired: 'This field is required',
      heading: 'Create improvement goal',
      goalHeading: 'Improvement goal',
      editHeading: 'Edit improvement goal',
      knowledgeLabel: 'Knowledge',
      knowledgeSharingLabelLong: 'Knowledge sharing',
      knowledgeSharingLabel: 'Sharing',
      influenceLabel: 'Social',
      collaborationLabel: 'Collaboration',
      subjectKnowledgeLabel: 'Knowledge',
      engagingLabel: 'Engaging',
      relevanceLabel: 'Relevance',
      satisfactionLabel: 'Satisfaction',
      categoriesLabel: 'Choose improvement categories',
      name: 'Give your goal a name',
      endDate: 'When do you want your goal to be acheived (recommended 6 months)',
      goal: 'Describe your goal',
      situation: 'Describe the starting situation',
      plan: 'Describe your execution plan to acheive the goal',
      result: 'Describe the result of the work during the period of the goal',
      submit: 'Create goal',
      submitEdit: 'Update goal',
      serverError: 'We could not save your goal. Please try again or contact support',
      accessibleBy: 'Accessibly by',
      accessibleByMe: 'Only me',
      accessibleBySupporters: 'Supporters',
      accessibleByOrganization: 'Everyone in the organization',
      goalDescriptionHeading: 'Goal description',
      improvementCategories: 'Improvement categories',
      initialSituationHeading: 'Initial situation',
      planHeading: 'Execution plan',
      registeredLabel: 'Started: {1}',
      endDateLabel: 'End date: {1}',
      sharedGoalsTitle: 'Shared improvement goals',
      goalsTitle: 'Improvement goals',
      originalSurvey: 'Original survey',
      latestSurvey: 'Latest survey',
      phasesInitial: '1. Planning',
      phasesExec: '2. Execution',
      phasesConclusion: '3. Conclusion',
      latestSurveys: 'Latest surveys',
      createSurvey: 'Create a new survey',
      comments: 'Comments',
      conclusionHeading: 'Conclusion',
      statusongoing: 'Ongoing',
      statusfinished: 'Finished',
      noRecentSuveys: 'There are no suveys created after the start of the goal',
      conclusionEditHeading: 'How did it go?',
      submitConclusion: 'Save changes',
      isSuccessLabel: 'Would you say that the goal was acheived?',
      isSuccessYes: 'Yes',
      isSuccessNo: 'No',
      success: 'Success',
      fail: 'Failure',
      journalHeading: 'Journal',
      patienceConclusion: 'Conclusion tab will become available towards the end of the goal period',
      emptyConclusion: 'Edit goal to add your conclusion thoughts',
      status_finished: 'Status: Finished',
      status_planning: 'Status: Planning',
      status_conclusion: 'Status: Conclusion',
      status_execution: 'Status: Execution',
      createNewGoal: 'Create a new improvement goal',
      sharedBy: 'Shared by {1}',
      orgRecentSurveyResults: 'Recent survey results',
      orgResultsFromTo: 'Results from {1} to {2}',
      orgOriginalResultsFromTo: 'Original Results from {1} to {2}',
      totalSurveys: 'Surveys',
      totalAnsvars: 'Answers',
      orgGoalCategoriesPeriod: 'Results from last six months',
    },
    systemMessages: {
      loading: 'Loading data...',
      notFound: 'The content you are looking for could not be found',
      surveyEnded: 'This survey is regrettable finished',
      alreadyResponded: 'You have already responded to this survey',
      login: 'Logging in...',
      errorLogin: 'Login failed...',
      verifyEmail: 'You need to verify your email address. Check your email and log in again.',
      invalidDomain: 'Feedbach a solution for organizations, and the users are connected using the email domain. The domain you have used to log on with is shared with others, and can unfortunately not be used.',
    },
    userManagementForm: {
      name: 'Name',
      department: 'Department',
      role: 'Role',
      departmentDeleted: '{1} was deleted',
      title: 'Users',
      roleOwner: 'Owner',
      roleMember: 'Member',
      roleInvited: 'Invited',
      roleSupporter: 'Supporter',
      departmentsTitle: 'Departments',
      noDepartments: 'No departments are added',
      editDepartment: 'Edit departments',
      addDepartment: 'Add a department',
      departmentNameLabel: 'Add department',
      submit: 'Save departments',
      noDepartment: 'No department',
      goBack: 'Close',
      invalidDepartment: 'You need to provide a name for the department',
      inviteUsersTitle: 'Invite users',
      inviteUsersDescription: 'You can invite users from your organization by sharing the link below with them.',
      editGroups: 'Add or remove groups',
      addGroup: 'Add groups',
      editGroup: 'Edit groups',
      groupNameLabel: 'Group name',
      invalidGroup: 'You need to provide a name for the group',
      memberOf: 'Member of',
      availableGroups: 'Other groups',
      groupsHeading: 'Groups',
      save: 'Save',
      accessFor: 'Accessibly by',
      noGroups: 'The organization has no groups yet',
    },
    cardForm: {
      survey: 'Survey',
      surveyTypeFilterLabel: 'Survey type',
      cardTitle: 'Text on the card',
      noCards: 'You have not created any Feedbach cards yet',
      noSharedCards: 'There has not been created any Feedbach cards yet.',
      startingSurvey: 'From, and including',
      endingSurvey: 'To, and including',
      heading: 'Create Feedbach Card',
      onlyOneCard: 'You need to register- or join an organization to create more Feedbach cards.',
      preview: 'Preview',
      createCard: 'Create new Feedbach Card',
      serverError: 'We could not save your card. Please try again or contact support',
      selectSurveyInfo: 'Select the interval of surveys that are to be included in the card.',
      surveys: 'Surveys',
      selectedHeading: 'Survey interval',
      submit: 'Create card',
      invervalError: 'You need to select to- and from evaluation. It can be the same.',
      noSurveys: 'You have not had any surveys yet. You need to have at least one survey to create a Feedbach card.',
    },
    menu: {
      settings: 'Settings',
      surveys: 'Surveys',
      sharedSurveys: 'Shared surveys',
      sharedCards: 'Shared cards',
      card: 'Feedbach cards',
      logout: 'Log out',
      goals: 'Improvement goals',
      sharedGoals: 'Shared improvement goals',
      orgDash: 'Organization Dashboard',
      myFeedbach: 'My Feedbach',
      sharedFeedbach: 'Shared Feedbach',
    },
    settings: {
      heading: 'Settings',
      profileImageHeading: 'Change profile image',
      saveImageBtn: 'Save',
      imageMissing: 'You need to choose an image',
      existingImage: 'Existing profile image',
      profile: 'Profile',
      organization: 'Organization',
      members: 'Users and roles',
      detailsHeading: 'Subscription Details',
    },
    frontpage: {
      subTitle: 'Knowledge bank for continuous improvement!'
    },
    dashboard: {
      title: 'Overview',
      seeMoreRecommendations: 'See more recommendations',
      expiredSubTitle: 'It seems like the subscription has expired!',
      expiredSubBody: `You can still look around, but you can't create new surveys. Click the button below to renew your subscription.`,
      expiredSubBodyNotOwner: `You can still look around, but you can't create new surveys. Please contact the owner of the organization.`,
      noSubTitle: 'Could not find an active subscription!',
      noSubBody: `You can still look around, but you can't create new surveys. Click the button below to activate a subscription.`,
      trialExpiredSubTitle: 'It seems like the trial period has expired.',
      trialExpiredSubBody: `You can still look around, but you can't create new surveys. Click the button below to activate a subscription.`,
      activeSubscription: 'Active subscription',
      noOrgTitle: 'You have not registered an organization yet',
      noOrgDescription: 'You should register an organization to get most out of Feedbach. Get started here.',
      noOrgButton: 'Register organization',
    },
    orgForm: {
      title: 'Register your organization',
      updateTitle: 'Update Organization Data',
      orgName: 'Organization name',
      orgNo: 'VAT number',
      country: 'Country',
      submit: 'Submit',
      domain: 'Domain - Users with email on this domain will be added to the organization',
      fieldRequired: 'This field is required',
      formErrors: 'Oops, there are some errors here',
      logoLabel: 'Upload company logo',
      receiptText: 'Company information was updated',
      consentToTerms: 'I consent to the terms of the data processing agreement',
      termsLink: 'Read the terms of the data processing agreement',
      consentToTermsRequired: 'You need to accept the data processing agreement',
      consentToAgreement: 'I accept the terms of the general agreement',
      consentToAgreementRequired: 'You need to accept the content of the general agremeent',
      agreementLink: 'Read the general agreement',
    },
    surveys: {
      title: 'Surveys',
      onlyOneSurvey: 'You need to register- or join an organization to create more surveys.',
      noSurveys: 'You have not created any surveys yet.',
      noSharedSurveys: 'No surveys have been shared with you yet.',
      responderNotesHeading: 'Notes',
      submitResponderNotes: 'Save',
      noResponderNotesText: 'No notes are added',
      listItemHeading: 'Survey',
      ends: 'ends',
      respondents: 'Respondents: {1}/{2}',
      start: 'Start: {1}',
      end: 'End: {1}',
      changed: 'Changed {1}',
      status_upcoming: 'Status: Upcomming',
      status_ongoing: 'Status: Active',
      status_finished: 'Status: Finished',
      surveyHeading: 'Survey {1}-{2}',
      titleNameOngoing: '{1}, ongoing until {2}',
      titleNameFinished: '{1}, ended {2}',
      titleFinished: 'Survey, ended {1}',
      titleOngoing: 'Survey, ongoing until {1}',
      createSurvey: 'Create new survey',
      reflectionsHeading: 'Reflections',
      noReflectionsText: 'No reflections added',
      submitReflections: 'Save reflections',
      sharedBy: 'Shared by',
      creator_onBehalfOf: 'Created by',
      sharedHeading: 'Shared surveys',
    },
    questions: {
      knowledge: 'How would you range the professional skills of {1}?',
      knowledgeInfo: `How is the worker's knowledge within the field of expertise compared to what has been promised or your expectations? How is the worker's abilities to get acquainted with your needs?`,
      knowledgeSharing: 'How would you range the ability of {1} to share her/his knowledge and experiences?',
      knowledgeSharingInfo: `Does the worker contribute with her/his experiences and knowledge in a constructive way to provide solutions to your needs?`,
      influence: 'How would you range the contributions of {1} to the working environment?',
      influenceInfo: `To what degree does the worker participate and contribute to the social environment in your work place?`,
      collaboration: 'How would you range the collaboration skills of {1}?',
      collaborationInfo: `How easy is it to collaborate with the worker, and how is the contribution within- and among the teams of the organization?`,
      subjectKnowledge: 'How would you rate the professional knowledge of {1} on the subject that was presented?',
      subjectKnowledgeInfo: `What was the level of expertise within the subject that was presented? Was the content presented in a way that was easy to understand, and was it possible to get answers to questions?`,
      engaging: 'How would you rate communication skills of {1}?',
      engagingInfo: 'Was the content presented in an engaging manner, and was it possible to actively participate in the course/workshop? How did you experience the learning process?',
      relevance: 'How relevant and useful was the content of the course/workshop to your needs?',
      relevanceInfo: 'Was the course/workshop relevant compared to what you expected? Was the information up-to-date and useful?',
      satisfaction: 'How would you rate your overall satisfaction with the course/workshop delivered by {1}?',
      satisfactionInfo: 'Did the course/workshop meet your expectations?',
      expertise: 'How would you rate the professional competence and expertise of {1}?',
      expertiseInfo: 'How is the consultant\'s knowledge within their area of expertise compared to your expectations? How is the consultant\'s ability to understand your needs?',
      communication: 'How satisfied are you with the communication and availability of {1} throughout the project?',
      communicationInfo: 'Did you feel that your questions were answered without having to wait too long? Were the consultant\'s promises fulfilled?',
      expectations: 'How well did you feel that {1} understood and met your needs and expectations?',
      expectationsInfo: 'Was the delivery as you expected, and would you use the same service again?',
      quality: 'How would you rate the overall quality of the service delivered by {1}?',
      qualityInfo: 'Did you find the service to be of high quality and that the consultant delivered what was promised, to the standard you expected?',
      summary: 'Summary',
      totalScore: 'Total score',
      invitedHeading: 'Invited',
      recommendationsHeading: 'Recommendations',
      messagesHeading: 'Feedback messages',
      resultAnswers: 'Number of answers: {1}',
      resultDontKnows: `Number of don't knows: {1}`,
      responseHeading: 'Response from {1}',
      recommendationHeading: 'Recommendation!',
      messageHeading: 'Feedback',
      answersHeading: 'Answers',
      commentsHeading: 'Notes and comments',
      messageColumn: 'Message',
      columnHeading: 'From',
      dontKnow: `Don't know`,
      hasNotResponded: '{1} has not responded yet.',
      knowledgeLabel: 'Knowledge',
      knowledgeSharingLabel: 'Knowledge Sharing',
      influenceLabel: 'Social',
      collaborationLabel: 'Collaboration',
      subjectKnowledgeLabel: 'Knowledge',
      engagingLabel: 'Engaging',
      relevanceLabel: 'Relenvance',
      satisfactionLabel: 'Satifaction',
      qualityLabel: 'Quality',
      expectationsLabel: 'Expectations',
      communicationLabel: 'Communication',
      expertiseLabel: 'Expertise',
      score10: 'Score is {1}, meaning that the worker is absolutely world class. You should save the 10 for a very special occasion. Are you sure there are no areas for improvement?',
      score9: 'Score is {1}, meaning that the worker is excellent. Performance on this level is unusual. Are you sure there are no areas for improvement?',
      score7to8: 'Score is {1}, meaning that the worker is great. This is a good score, and the worker is doing a great job.',
      score5to6: 'Score is {1}, meaning that the worker is good. This is an average score, and the worker is doing OK, however there are some areas that could be improved. Maybe you should let the worker know what you think in the notes and comments?',
      score1to4: 'Score is {1}, meaning that the worker is not good. This is a low score, and the worker is not meeting the expectations. Maybe you should let the worker know what you think in the notes and comments?',
      feedbackFromHeading: 'Feedback from {1}',
    },
    surveyForm: {
      surveyTypeLongTerm: 'Long term assignment',
      surveyTypeWorkshop: 'Workshop or course',
      surveyTypeDelivery: 'Service delivery',
      surveyTypeLabel: 'Survey type',
      surveyTypeNoChange: 'The survey type can not be changed after the survey is created',
      heading: 'New survey',
      skipForLater: 'You may skip this step and return to it later, when the survey is created.',
      emailPreviewTitle: 'Invitation email preview',
      next: 'Next',
      surveyAccessStep: 'Access',
      previous: 'Previous',
      editHeading: 'Edit the Survey',
      defaultLang: 'Standard language',
      candidate: 'Your name (on the invitation)',
      startDate: 'Start date',
      endDate: 'End date',
      invitationText: 'Invitation text',
      submit: 'Create survey',
      submitEdit: 'Save changes',
      fieldRequired: 'This field is required',
      dateLogic: 'End date must be later than start date',
      minimumDuration: 'End date must be more than one week ahead',
      formErrors: 'Oops, there are a few answers missing',
      serverError: 'There was an error when we tried to store your survey. Please try again, or send an email about the problem to hello@feedbach.me',
      surveyName: 'Survey name',
      accessibleBy: 'Accessibly by',
      accessibleByMe: 'Only me',
      accessibleBySupporters: 'Supporters',
      accessibleBySelectedGroups: 'Selected groups',
      accessibleByOrganization: 'Everyone in the organization',
      onBehalfOf: 'Create survey on behalf of',
      accessHeading: 'Survey Access',
      groupsHeading: 'Give access to groups',
    },
    invites: {
      title: 'Invite responders',
      new: 'Invited',
      serverError: 'There was an error when we tried to send the invitation. Make sure the email addresses are correct and are not already invited, and try again.',
      en: 'English',
      no: 'Norwegian',
      responded: 'Has answered',
      emailed: 'Has received email',
      submit: 'Invite',
      emailLabel: 'E-mail',
      invitedHeading: 'Invited',
      invitationTextHeading: 'Invitation text',
      invalidEmail: 'The e-mail address is not valid',
      maxEmailsExceededNoOrg: 'You have reached the maximum number of invites. Please register an organization to invite more people.',
      maxEmailsExceeded: 'You have reached the maximum number of invites.',
      invitesInfo: 'We recommand that you discretely follow up your invites. If they do not respond after some time, you should ask them if they received the invitation, and have them check their spam folder.',
    },
    responderForm: {
      responsesNotAnonymous: 'The responses you provide here are not anonymous, and will be identifiable by {1}. This is to give {1} the chance to follow up on the results and use the results for improvement purposes. Please provide as honest feednack as possible.',
      title: 'Survey for',
      giveRecommendation: 'Would you like to give a recommendation?',
      recommendationName: 'What is your name?',
      recommendationNameInfo: 'We ask for your name and title to add credibility when the recommendation is presented in CVs, etc.',
      recommendationTitle: 'What is your title?',
      invitationHeading: 'Invitation',
      messageLabel: 'Additional feedback (optional)',
      recommendationLabel: 'Recommendation',
      recommendationInfo: 'If you want, you can include a recommendation, a text that {1} can use in CVs, on web pages and in offers where she/he is presented.',
      submit: 'Send',
      allQuesstionsRequired: 'This question must be answered',
      formErrors: 'Oops, there are a few answers missing',
      serverError: 'There was an error when we tried to store your answers. Please try again, or send an email about the problem to hello@feedbach.me',
      thankYouHeading: 'Thank you!',
      receiptText: 'Your answers are greatly appreciated and valuable. If there are additional information you would like to add, please reach out to {1} and talk about it.',
    },
    card: {
      registered: 'Registered {1} - ',
      noOfResponders: 'Number of answers',
      latestResponse: 'Latest answer',
      totalScoreLabel: 'Total',
      knowledgeLabel: 'Knowledge',
      sharingLabel: 'Sharing',
      influenceLabel: 'Social',
      collaborationLabel: 'Collaboration',
      subjectKnowledgeLabel: 'Subject Knowledge',
      engagingLabel: 'Engaging',
      relevanceLabel: 'Relevance',
      satisfactionLabel: 'Satisfaction',
      qualityLabel: 'Quality',
      expectationsLabel: 'Expectations',
      communicationLabel: 'Communication',
      expertiseLabel: 'Expertise',
      cardStatsHeading: 'Feedbach Card Info',
      responderDomains: 'Responder domains',
      uniqueResponders: 'Unique responders',
      earliestResponse: 'Earliest response',
      statsLatestResponse: 'Latest response',
      moreInfo: 'More info',
      noOfSurveys: 'Nomber of surveys',
    },
    createProfile: {
      serverError: 'An error occured when saving the profile. Please try again.',
      nameMissing: 'Full name is required.',
      candidate: 'Full name',
      title: 'Job title',
      heading: 'Create your profile',
      saveBtn: 'Create profile',
      profileImageLabel: 'Profile image',
      editHeading: 'Update profile',
      emailMissing: 'The user you logged in with is missing email. Please contact support.',
      consentToTerms: 'I accept the terms of the Agreement',
      consentToTermsRequired: 'You need to accept the terms of the Agreement',
      termsLink: 'Read the Agreement',
    },
  },
  no: {
    smileyFaceRating: {
      ratingInfo: 'Vi i Feedbach elsker tilbakemeldinger, og vi vil sette stor pris på hva du synes om vår løsning for å samle tilbakemeldinger. Vennligst ranger oss med et smilefjes, og la oss vite hva du synes.',
      receipt: 'Tusen takk for din tilbakemelding!',
    },
    misc: {
      serverError: 'Det oppsto en feil. Vennligst prøs igjen eller kontakt hei@feedbach.me',
      languageSelectorLabel: 'Språk',
      copyToClipboard: 'Kopier',
      copiedToClipboard: 'Kopiert!',
      errorCopying: 'Kopiering feilet',
    },
    settings: {
      heading: 'Innstillinger',
      profileImageHeading: 'Oppdatere profilbilde',
      saveImageBtn: 'Lagre',
      imageMissing: 'Du må velge et bilde',
      existingImage: 'Eksisterende profilbilde',
      updateNameBtn: 'Oppdatere navn',
      candidate: 'Navn',
      fieldRequired: 'Du må fylle ut navn',
      profile: 'Profil',
      organization: 'Organisasjon',
      members: 'Brukere og roller',
    },
    menu: {
      settings: 'Innstillinger',
      card: 'Feedbach-kort',
      surveys: 'Evalueringer',
      sharedSurveys: 'Delte evalueringer',
      sharedCards: 'Delte kort',
      goals: 'Forbedringsmål',
      logout: 'Logg ut',
      sharedGoals: 'Delte forbedringsmål',
      orgDash: 'Organisasjonsoversikt',
      myFeedbach: 'Min Feedbach',
      sharedFeedbach: 'Delt Feedbach',
    },
    frontpage: {
      subTitle: 'Kunnskapsbank for kontinuerlig forbedring!'
    },
    dashboard: {
      title: 'Oversikt',
      seeMoreRecommendations: 'Se flere anbefalinger',
      expiredSubTitle: 'Det ser ut som abonnementet er utgått!',
      expiredSubBody: 'Du kan fortsatt se deg rundt, men ikke opprette nye evalueringer eller brukere. Klikk på knappen under for å aktivere abonnementet igjen.',
      noSubTitle: 'Fant ikke et aktivt abonnement!',
      expiredSubBodyNotOwner: `Du kan fortsatt se deg rundt, men ikke opprette nye evalueringer eller brukere. Vennligst kontakt brukeren med eierrolle..`,
      noSubBody: 'Du kan fortsatt se deg rundt, men ikke opprette nye evalueringer eller brukere. Klikk på knappen under for å aktivere et abonnement.',
      trialExpiredSubTitle: 'Det ser ut som prøveperioden er utgått!',
      trialExpiredSubBody: 'Du kan fortsatt se deg rundt, men ikke opprette nye evalueringer eller brukere. Klikk på knappen under for å aktivere et abonnement.',
      activeSubscription: 'Aktivér abonnement',
      noOrgTitle: 'Du har ikke registrert en organisasjon enda',
      noOrgDescription: 'Du bør registrere en organisasjon for å få mest mulig ut av Feedbach. Kom i gang her.',
      noOrgButton: 'Registér organisasjon',
    },
    orgForm: {
      logoLabel: 'Last opp firmalogo',
      updateTitle: 'Oppdatere organisasjonen',
      title: 'Registrér din organisasjon',
      orgName: 'Bedriftens navn',
      orgNo: 'Organisasjonsnummer',
      country: 'Land',
      domain: 'Domene - brukere med epost på dette domenet meldes inn i organisasjonen',
      submit: 'Registrér',
      fieldRequired: 'Dette feltet er påkrevd',
      formErrors: 'Oops, det er noen feil her',
      receiptText: 'Bedriftsinformasjonen ble oppdatert',
      consentToTerms: 'Jeg aksepterer innholdet i databehandleravtalen',
      consentToTermsRequired: 'Du må akseptere innholdet i databehandleravtalen',
      termsLink: 'Les igjennom databehandleravtalen',
      consentToAgreement: 'Jeg aksepterer innholdet i de generelle betingelsene',
      consentToAgreementRequired: 'Du må akseptere innholdet i de generelle betingelsene',
      agreementLink: 'Les igjennom de generelle betingenlsene',
    },
    surveys: {
      title: 'Evalueringer',
      onlyOneSurvey: 'Du må registrere- eller melde deg inn i en organisasjon for å opprette flere evalueringer.',
      noSurveys: 'Du har ikke opprettet noen evalueringer enda.',
      responderNotesHeading: 'Notater',
      submitResponderNotes: 'Lagre',
      noResponderNotesText: 'Ingen notater er lagt inn',
      noSharedSurveys: 'Ingen evalueringer er delt med deg enda.',
      listItemHeading: 'Evaluering',
      ends: 'avsluttes',
      respondents: 'Besvarelser: {1}/{2}',
      start: 'Start: {1}',
      end: 'Slutt {1}',
      changed: 'Endret {1}',
      status_upcoming: 'Status: Kommende',
      status_ongoing: 'Status: Aktiv',
      status_finished: 'Status: Avsluttet',
      surveyHeading: 'Evaluering {1}-{2}',
      titleNameOngoing: '{1}, pågår til {2}',
      titleNameFinished: '{1}, avsluttet {2}',
      titleFinished: 'Evaluering, avsluttet {1}',
      titleOngoing: 'Evaluering, pågår til {1}',
      createSurvey: 'Opprett ny evaluering',
      reflectionsHeading: 'Refleksjoner',
      noReflectionsText: 'Ingen refleksjoner er lagt inn',
      submitReflections: 'Lagre refleksjoner',
      serverError: 'Lagring av evalueringsdata feilet',
      sharedBy: 'Delt av',
      creator_onBehalfOf: 'Opprettet av',
      sharedHeading: 'Delte evalueringer',
    },
    questions: {
      knowledge: 'Hvordan vil du rangere {1} sine faglige evner?',
      knowledgeInfo: `Hvordan er konsulentens kunnskap innenfor sitt ekspertiseområde sammenlignet med lovnader og forventninger. Hvordan er konsulentens evne til å sette seg inn i deres behov?`,
      knowledgeSharing: 'Hvordan vil du rangere {1} sine evner til å dele av sin kunnskap og erfaringer?',
      knowledgeSharingInfo: `Bidrar konsulenten gjennom sine erfaringer og ekspertise på en konstruktiv måte knyttet til deres behov?`,
      influence: 'Hvordan vil du rangere {1} sitt bidrag til arbeidsmiljøet?',
      influenceInfo: `På hvilken måte vil du si at konsulenten er deltakende og engasjert i det sosiale miljøet hos dere?`,
      collaboration: 'Hvordan vil du rangere {1} sine samarbeidsevner?',
      collaborationInfo: `Hvor enkelt er det å samarbeide med konsulenten, og hvordan er bidraget i og mellom teamene hos dere?`,
      subjectKnowledge: 'Hvordan vil du rangere {1} sin faglige kunnskap om emnet som ble presentert?',
      subjectKnowledgeInfo: `Hvordan var kompetansenivået innenfor emnet som ble presentert? Ble innholdet presentert på en måte som var lett å forstå og var det mulig å få svar på spørsmål?`,
      engaging: 'Hvordan vil du rangere {1} sin formidlingsevne?',
      engagingInfo: 'Ble innholdet presentert på en engasjerende måte, og var det mulig å delta aktivt i kurset/workshopen? Hvordan opplevde du læringen?',
      relevance: 'Hvor relevant og nyttig var innholdet i kurset/workshopen for dine behov?',
      relevanceInfo: 'Var kurset/workshoppen relevant sammenlignet med det du hadde forventet? Var informasjonen oppdatert og nyttig?',
      satisfaction: 'Hvordan vil du rangere din samlede tilfredshet med kurset/workshopen levert av {1}?',
      satisfactionInfo: 'Oppfylte kurset/workshopen dine forventninger?',
      expertise: 'Hvordan vil du rangere {1} sin faglige kompetanse og ekspertise?',
      expertiseInfo: 'Hvordan er konsulentens kunnskap innenfor sitt ekspertiseområde sammenlignet med dine forventninger? Hvordan er konsulentens evne til å forstå dine behov?',
      communication: 'Hvor fornøyd er du med {1} sin kommunikasjon og tilgjengelighet gjennom oppdraget?',
      communicationInfo: 'Opplevde du å få svar på dine spørsmål uten å måtte vente for lenge? Ble konsulentens løfter oppfylt?',
      expectations: 'Hvor godt opplevde du at {1} forstod og ivaretok dine behov og forventninger?',
      expectationsInfo: 'Ble leveransen som du forventet, og vil du benytte deg av samme tjeneste igjen?',
      quality: 'Hvordan vil du rangere den totale kvaliteten på tjenesten levert av {1}?',
      qualityInfo: 'Opplevde du at tjenesten var av høy kvalitet og at konsulenten leverte det som var lovet, til den standarden du forventet?',
      summary: 'Oppsummering',
      totalScore: 'Totalscore',
      invitedHeading: 'Inviterte',
      recommendationsHeading: 'Anbefalinger',
      messagesHeading: 'Tilbakemeldinger',
      resultAnswers: 'Antall svar: {1}',
      resultDontKnows: 'Vet ikke: {1}',
      responseHeading: 'Besvarelse fra {1}',
      recommendationHeading: 'Anbefaling',
      messageHeading: 'Tilbakemelding',
      answersHeading: 'Svar',
      messageColumn: 'Tilbakemelding',
      columnHeading: 'Fra',
      dontKnow: 'Vet ikke',
      hasNotResponded: '{1} har ikke svart enda.',
      commentsHeading: 'Kommentarer og notater',
      knowledgeLabel: 'Kompetanse',
      knowledgeSharingLabel: 'Kompetansedeling',
      influenceLabel: 'Arbeidsmiljø',
      collaborationLabel: 'Samarbeid',
      subjectKnowledgeLabel: 'Kunnskap',
      engagingLabel: 'Engasjerende',
      relevanceLabel: 'Relevans',
      satisfactionLabel: 'Tilfredshet',
      qualityLabel: 'Kvalitet',
      expectationsLabel: 'Forventninger',
      communicationLabel: 'Kommunikasjon',
      expertiseLabel: 'Ekspertise',
      score10: 'Score er {1}, noe som betyr at konsulenten er helt i verdenstoppen. Du bør spare 10-eren til en veldig spesiell anledning. Er du sikker på at det ikke er noen forbedringsområder?',
      score9: 'Score er {1}, noe som betyr at konsulenten er utmerket. Prestasjon på dette nivået er uvanlig. Er du sikker på at det ikke er noen forbedringsområder?',
      score7to8: 'Score er {1}, noe som betyr at konsulenten er veldig god. Dette er en god score, og konsulenten gjør en god jobb.',
      score5to6: 'Score er {1}, noe som betyr at konsulenten er god. Dette er en gjennomsnittlig score og konsulenten klarer seg greit. Samtidig er det noen områder som kan forbedres. Kanskje du skal la konsulenten få vite mer om hva du tenker du i tilbakemeldingsfeltet?',
      score1to4: 'Score er {1}, noe som betyr at konsulenten ikke er god. Dette er en lav score, og konsulenten møter ikke forventningene. Kanskje du skal la konsulenten få vite mer om hva du tenker du i tilbakemeldingsfeltet?',
      feedbackFromHeading: 'Tilbakemelding fra {1}',
    },
    surveyForm: {
      surveyTypeLongTerm: 'Langtidsoppdrag',
      surveyTypeWorkshop: 'Workshop eller kurs',
      surveyTypeDelivery: 'Tjenesteleveranse',
      surveyTypeLabel: 'Evalueringstype',
      surveyTypeNoChange: 'Evalueringstypen kan ikke endres når evalueringen er opprettet',
      accessHeading: 'Tilganger',
      heading: 'Ny evaluering',
      surveyAccessStep: 'Tilgang og tilgjengelighet',
      invitesHeading: 'Invitere mottakere',
      skipForLater: 'Du kan hoppe over dette steget og komme tilbake til det senere, når evalueringen er opprettet.',
      emailPreviewTitle: 'Forhåndsvisning av invitasjonsepost',
      next: 'Neste',
      previous: 'Tilbake',
      defaultLang: 'Standardspråk',
      editHeading: 'Endre evalueringen',
      candidate: 'Ditt navn (på invitasjonen)',
      startDate: 'Startdao',
      endDate: 'Sluttdato',
      invitationText: 'Invitasjonstekst',
      submit: 'Opprett evaluering',
      submitEdit: 'Lagre endringene',
      fieldRequired: 'Dette feltet er påkrevd',
      formErrors: 'Oops, det er noen feil her',
      dateLogic: 'Sluttdato må være etter startdato',
      minimumDuration: 'Sluttdato kan ikke være mindre enn en uke frem i tid',
      serverError: 'Det oppsto en feil ved lagring av evalueringen din. Vennligst prøv igjen, eller send en epost om problemet til hello@feedbach.me',
      surveyName: 'Navn på evalueringen',
      accessibleBy: 'Generelt tilgangsnivå',
      accessibleByMe: 'Bare meg',
      accessibleBySupporters: 'Støttespillere',
      accessibleBySelectedGroups: 'Valgte grupper',
      accessibleByOrganization: 'Hele organisasjonen',
      onBehalfOf: 'Opprett evaluering på vegne av',
    },
    invites: {
      title: 'Invitere mottakere',
      new: 'Invitert',
      serverError: 'Det oppsto en feil ved utsending av invitasjonen. Sjekk at epostadressene er korrekte og ikke allerede invitert, og prøv igjen.',
      en: 'Engelsk',
      no: 'Norsk',
      responded: 'Har svart',
      emailed: 'Har mottatt epost',
      submit: 'Invitér',
      emailLabel: 'E-post',
      invitedHeading: 'Inviterte',
      invitationTextHeading: 'Invitasjonstekst',
      invalidEmail: 'Epostadressen er ikke gyldig',
      maxEmailsExceededNoOrg: 'Du har nådd maks antall invitasjoner. Vennligst registrer en organisasjon for å invitere flere.',
      maxEmailsExceeded: 'You have reached the maximum number of invites.',
      invitesInfo: 'Vi anbefaler at du diskret følger opp invitasjonene dine. Hvis de ikke svarer etter en stund, bør du spørre om de har mottatt invitasjonen, og be dem sjekke spam-mappen.',
    },
    responderForm: {
      responsesNotAnonymous: 'Dine svar du gir her er ikke anonyme, og vil være identifiserbare av {1}. Dette er for å gi {1} muligheten til å følge opp resultatene og bruke resultatene til forbedringsformål. Vennligst gi så ærlige svar som mulig.',
      title: 'Evalueringsskjema for',
      invitationHeading: 'Invitasjon',
      messageLabel: 'Øvrig tilbakemelding (valgfritt)',
      recommendationLabel: 'Anbefalingstekst',
      recommendationInfo: 'Hvis du vil, kan du inkludere en anbefaling, en tekst som {1} kan bruke i CVen, på websider, i tilbud eller lignende.',
      submit: 'Send',
      allQuesstionsRequired: 'Dette spørsmålet må besvares',
      formErrors: 'Oops, det mangler noen svar',
      serverError: 'Det oppsto en feil ved lagring av svaret ditt. Vennligst prøv igjen, eller send en epost om problemet til hello@feedbach.me',
      thankYouHeading: 'Tusen takk!',
      receiptText: 'Dine svar blir satt pris på, og er verdifulle! Hvis du har mer informasjon, vennligst ta kontakt med {1} og fortell om det.',
      giveRecommendation: 'Vil du gi en anbefaling av {1}?',
      recommendationName: 'Hva er ditt navn?',
      recommendationNameInfo: 'Vi ber om navn og tittel for å øke troverdigheten når anbefalingen er presentert i CVer, etc.',
      recommendationTitle: 'Hva er din tittel?',
    },
    cardForm: {
      heading: 'Opprett Feedbach-kort',
      surveyTypeFilterLabel: 'Evalueringstype',
      cardTitle: 'Tekst på kortet',
      noCards: 'Du har ikke opprettet noen Feedbach-kort enda',
      noSharedCards: 'Det er ikke opprettet noen delte Feedbach-kort enda',
      survey: 'Evaluering',
      startingSurvey: 'Fra og med',
      endingSurvey: 'Til og med',
      selectSurveyInfo: 'Velg intervallet av evalueringer som skal inkluderes i kortet.',
      surveys: 'Evalueringer',
      selectedHeading: 'Evalueringsintervall',
      preview: 'Forhåndsvisning',
      submit: 'Opprett',
      invervalError: 'Du må velge fra- og til-evaluering. Det kan være den samme.',
      createCard: 'Opprett nytt Feedbach-kort',
      onlyOneCard: 'Du må registrere- eller melde deg inn i en organisasjon for å opprette flere Feedbach-kort.',
      serverError: 'Det oppsto en feil ved lagring av kortet. Vennligst prøv igjen senere eller kontakt support.',
      noSurveys: 'Du har ikke hatt noen evalueringer enda. Du må ha minst en evaluering for å opprette et Feedbach-kort.',
    },
    card: {
      registered: 'Opprettet {1} - ',
      noOfResponders: 'Antall svar',
      latestResponse: 'Siste besvarelse',
      totalScoreLabel: 'Total',
      knowledgeLabel: `Kompetanse
 `,
      knowledgeSharingLabel: `Kompetanse-
deling`,
      influenceLabel: `Arbeidsmiljø
 `,
      collaborationLabel: `Samarbeid
 `,
      subjectKnowledgeLabel: `Emne-
kunnskap`,
      engagingLabel: `Engasjerende
  `,
      relevanceLabel: `Relevans
  `,
      satisfactionLabel: `Tilfredshet
  `,
      qualityLabel: ` 
Kvalitet`,
      expectationsLabel: `Forventninger
  `,
      communicationLabel: ` 
Kommunikasjon`,
      expertiseLabel: `Ekspertise
  `,
      cardStatsHeading: 'Feedbachkort: Mer info',
      responderDomains: 'Svardomener',
      uniqueResponders: 'Unike svarere',
      earliestResponse: 'Tidligste svar',
      statsLatestResponse: 'Siste svar',
      moreInfo: 'Mer info',
      noOfSurveys: 'Antall evalueringer',
    },
    createProfile: {
      emailMissing: 'Brukeren du logget på med mangler epost. Kontakt support.',
      serverError: 'Det oppsto en feil ved lagring av profilen din. Vennligst prøv igjen.',
      nameMissing: 'Fullt navn er påkrevd.',
      candidate: 'Fullt navn',
      heading: 'La oss bli litt bedre kjent',
      title: 'Stillingstittel',
      saveBtn: 'Opprett profil',
      profileImageLabel: 'Profilbilde',
      editHeading: 'Oppdatere profil',
      termsLink: 'Les personvernerklæringen',
      consentToTerms: 'Jeg samtykker til innholdet i avtalen',
      consentToTermsRequired: 'Du må akseptere innholdet i avtalen',
    },
    userManagementForm: {
      name: 'Navn',
      department: 'Avdeling',
      role: 'Rolle',
      departmentDeleted: '{1} er slettet',
      title: 'Brukere',
      roleOwner: 'Eier',
      roleMember: 'Medlem',
      roleInvited: 'Invitert',
      roleSupporter: 'Støttespiller',
      departmentsTitle: 'Avdelinger',
      noDepartments: 'Det er ikke lagt til noen avdelinger.',
      editDepartment: 'Endre avdelinger',
      addDepartment: 'Legg til avdeling',
      departmentNameLabel: 'Legg til avdeling',
      submit: 'Lagre',
      noDepartment: 'Ingen',
      goBack: 'Tilbake',
      invalidDepartment: 'Du må oppgi navn på avdelingen',
      inviteUsersTitle: 'Invitere brukere',
      inviteUsersDescription: 'Du kan invitere brukere fra organisasjonen din ved å dele lenken nedenfor med dem.',
      groupsHeading: 'Grupper',
      editGroups: 'Legg til- eller fjerne grupper',
      addGroup: 'Legg til grupper',
      editGroup: 'Endre grupper',
      groupNameLabel: 'Gruppenavn',
      invalidGroup: 'Du må oppgi et navn på gruppen',
      memberOf: 'Medlem av',
      availableGroups: 'Andre grupper',
      save: 'Lagre',
      accessFor: 'Tilgjengelig for',
      noGroups: 'Organisasjonen har ingen grupper enda.',
    },
    systemMessages: {
      loading: 'Henter data...',
      notFound: 'Kan ikke finne innholdet du prøver å hente',
      surveyEnded: 'Denne evalueringen er dessverre avsluttet',
      alreadyResponded: 'Du har allerede svart på denne evalueringen',
      login: 'Logger inn...',
      errorLogin: 'Pålogging feilet',
      verifyEmail: 'Du må verifisere epostadressen. Sjekk inboksen, og logg inn på nytt.',
      invalidDomain: 'Feedbach er beregnet for organisasjoner, og brukerne kobles til organisasjonen ved hjelp av epostdomenet. Domenet du har logget på med deles av andre, og kan dessverre ikke benyttes.',
    },
    improvementGoals: {
      noSelectedCategories: 'Ingen valgte kategorier',
      noSharedGoals: 'Det er ingen mål som er delt med deg',
      noGoals: 'Du har ikke satt noen forbedringsmål enda. Start med en evaluering og sett et forbedringsmål ut fra resultatene fra den.',
      editOrgHeading: 'Endre organisasjonsmål',
      orgHeadingCreate: 'Opprett et organisasjonsmål',
      create: 'Opprett et forbedringsmål',
      missingOriginalSurvey: 'Forbedringsmålet må opprettes fra en evaluering. Start fra en fullført evaluering og prøv igjen.',
      fieldRequired: 'Feltet er påkrevd',
      heading: 'Opprett et forbedringsmål',
      goalHeading: 'Forbedringsmål',
      editHeading: 'Endre forbedringsmål',
      knowledgeLabel: 'Kompetanse',
      knowledgeSharingLabelLong: 'Kompetansedeling',
      knowledgeSharingLabel: 'Deling',
      influenceLabel: 'Arbeidsmiljø',
      collaborationLabel: 'Samarbeid',
      subjectKnowledgeLabel: 'Kunnskap',
      engagingLabel: 'Engasjerende',
      relevanceLabel: 'Relevans',
      satisfactionLabel: 'Tilfredshet',
      categoriesLabel: 'Velg forbedringskategorier',
      name: 'Gi målet ditt et navn',
      endDate: 'Når vil du at målet skal være oppnådd? (anbefalt 6 måneder)',
      goal: 'Beskriv målet',
      situation: 'Beskriv start-situasjonen',
      plan: 'Beskriv gjennomføringsplanen for å oppnå målet',
      result: 'Beskriv resultatet av arbeidet med målet i perioden',
      submit: 'Opprett mål',
      submitEdit: 'Oppdater målet',
      serverError: 'Vi klarte ikke å lagre målet. Vennligst prøv igjen eller kontakt support.',
      accessibleBy: 'Tilgjengelig for',
      accessibleByMe: 'Bare meg',
      accessibleBySupporters: 'Støttespillere',
      accessibleByOrganization: 'Hele organisasjonen',
      goalDescriptionHeading: 'Målbeskrivelse',
      improvementCategories: 'Forbedringskategorier',
      initialSituationHeading: 'Start-situasjonen',
      planHeading: 'Plan for utførelse',
      registeredLabel: 'Startet: {1}',
      endDateLabel: 'Avslutning: {1}',
      sharedGoalsTitle: 'Delte forbedringsmål',
      goalsTitle: 'Forbedringsmål',
      originalSurvey: 'Opprinnelig evaluering',
      latestSurvey: 'Siste evaluering',
      phasesInitial: '1. Planlegging',
      phasesExec: '2. Utførelse',
      phasesConclusion: '3. Konklusjon',
      latestSurveys: 'Siste evaluering',
      createSurvey: 'Start en ny evaluering',
      comments: 'Kommentarer',
      conclusionHeading: 'Konklusjon',
      statusongoing: 'Pågående',
      statusfinished: 'Avsluttet',
      noRecentSuveys: 'Det er ikke opprettet noen evalueringer siden målet ble satt.',
      conclusionEditHeading: 'Hvordan gikk det?',
      submitConclusion: 'Lagre endringer',
      isSuccessLabel: 'Vil du vurdere at målet ble oppnådd?',
      isSuccessYes: 'Ja',
      isSuccessNo: 'Nei',
      success: 'Oppnådd',
      fail: 'Ikke oppnådd',
      journalHeading: 'Journal',
      patienceConclusion: 'Konklusjonen blir tilgjengelig mot slutten av forbedringsperioden.',
      emptyConclusion: 'Endre målet for å legge til konklusjon',
      status_finished: 'Status: Avsluttet',
      status_planning: 'Status: Planlegging',
      status_conclusion: 'Status: Konklusjon',
      status_execution: 'Status: Utførelse',
      createNewGoal: 'Opprett et nytt mål',
      sharedBy: 'Delt av {1}',
      orgRecentSurveyResults: 'Nylige evalueringsresultater',
      orgResultsFromTo: 'Resultater fra {1} til {2}',
      orgOriginalResultsFromTo: 'Opprinnelige resultater fra {1} til {2}',
      totalSurveys: 'Antall evalueringer',
      totalAnsvars: 'Antall svar',
      orgGoalCategoriesPeriod: 'Resultat fra siste seks måneder',
    },
    comments: {
      addCommentHeading: 'Legg til kommentar',
      fieldRequired: 'Kommentarteksten kan ikke være tom',
      serverError: 'Vi kunne ikke lagre din kommentar. Vennligst prøv igjen eller kontakt support',
      submit: 'Lagre kommentar',
    },
    orgDash: {
      scoreHeading: 'Organisasjonsscore',
      surveyTypeHeading_delivery: 'Evalueringstype - Tjenesteleveranse',
      "surveyTypeHeading_long-term": 'Evalueringstype - Langtidsoppdrag',
      surveyTypeHeading_workshop: 'Evalueringstype - Workshop eller kurs',
      orgScoreInfo: 'Disse verdiene viser score på tvers av alle evalueringer i organisasjonen. Private evalueringer er ikke inkludert.',
      periodForever: 'Siden starten',
      periodYear: 'Siste 12 måneder',
      period6m: 'Siste 6 måneder',
      period3m: 'Siste 3 måneder',
      periodLabel: 'Velg periode',
      periodInterval: 'Velg fra- og til',
      answersLabel: 'Antall svar',
      surveyLabel: 'Evalueringer',
      updateBtn: 'Oppdatér',
      toDate: 'Til',
      fromDate: 'Fra',
      candidatePlaceholder: '[konsulentens navn]',
      departmentLabel: 'Avdeling',
    },
    subscription: {
      activateSub: 'Aktivere abonnement',
      subscriptionOrderText: 'Start en ny 12-måneders periode med evalueringer og kontinuerlig forbedring',
      subscriptionOrderInfo: 'Du blir sendt til Stripe for å fullføre betalingen.',
      goToCheckout: 'Gå til betaling',
      subscriptionActivatedHeading: 'Abonnement aktivert',
      settingsHeading: 'Abonnement',
      detailsHeading: 'Abonnementsdetaljer',
      startDateLabel: 'Startdato',
      endDateLabel: 'Sluttdato',
      subscriptionStatus: 'Status',
      status_active: 'Aktivt',
      status_none: 'Ingen aktivt abonnement',
      status_ended: 'Utgått',
      status_created: 'Opprettet',
      openPortal: 'Administrere abonnement',
      subscriptionActivatedSuccess: 'Abonnementet er nå aktivert',
      subscriptionActivationError: 'Det oppsto en feil ved forsøk på å aktivere abonnementet. Vennligst prøv igjen, eller kontakt support.',
      checkoutCancelled: 'Betalingen ble avbrutt. Vennligst aktiver et abonnement for å fortsette å bruke Feedbach.',
    },
    join: {
      aboutTo: 'Du er i ferd med å melde deg inn i organisasjonen {1}',
      join: 'Meld deg inn i {1}',
      orgFetchError: 'Det oppsto en feil ved henting av organisasjonsinformasjon. Vennligst prøv igjen, eller kontakt support.',
      joinFailed: 'Det oppsto en feil under innmelding i organisasjonen. Vennligst prøv igjen, eller kontakt support.',
    },
  },
};

