
import { Box } from 'theme-ui';
import { useTranslations } from '../../contexts/Translation';
import { H1 } from '../Headers';
import Section from '../Section';
import { useEffect, useState } from 'react';
import { useApiData } from '../../contexts/ApiData';
import { v4 as uuid } from 'uuid';
import Button from '../Button';
import { OrgGoal, ScoresObject, ValidationError, ValidationResult } from '../../types';
import Input, { Select } from './Input';
import ErrorText from './ErrorText';
import { useNavigate } from 'react-router-dom';
import ImprovementSelector from './ImprovementSelector/ImprovementSelector';
import { sub, addDays } from 'date-fns';
import { scoreResult2ScoresObject } from '../../lib/mapping';
import { useOrgGoal } from '../../contexts/OrganizationGoal';
import getQuestionsForSurveyType from '../..//lib/getQuestionsForSurveyType';

type ComponentProps = {
  isEdit?: boolean;
  surveyId?: string | null;
}

const OrgGoalForm = ({ isEdit = false }: ComponentProps) => {
  const t = useTranslations('improvementGoals');
  const { get } = useApiData();
  const { goal } = useOrgGoal();
  const [ scores, setScores ] = useState<ScoresObject | undefined>(goal?.scoreData);
  const { post, put } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const navigate = useNavigate();
  const initialData: OrgGoal = {
     id: goal?.id || uuid(),
     endDate: goal?.endDate || (new Date(Date.now() + 26*7*24*3600000)).toISOString().substring(0,10),
     categories: goal?.categories || [],
     goal: goal?.goal || '',
     name: goal?.name || '',
     plan: goal?.plan || '',
     originalFrom: goal?.originalFrom || sub(new Date(), { months: 6 }).toISOString().substring(0, 10),
     originalTo: goal?.originalTo || addDays(new Date(), 365).toISOString().substring(0, 10),
     result: goal?.result || '',
     situation: goal?.situation || '',
     surveyType: goal?.surveyType || 'long-term',
  };
  const [formData, setFormData] = useState<OrgGoal>(initialData);

  const getStats = async () => {
    const response = await get(`/organization/stats?fromDate=${formData.originalFrom}&toDate=${formData.originalTo}`);
    if (response) {
      setScores(scoreResult2ScoresObject(response.questions))
    }
  };

  useEffect(() => {
    if (!isEdit) {
      getStats();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateData = ({ name, endDate }: OrgGoal): ValidationResult => {
    const errors = [];
    // const inOneWeek = (new Date(Date.now() + 7*24*3600000)).toISOString().substring(0, 10);

    if (!name) { errors.push({ name: 'name', message: t('fieldRequired')}) }

    if (!endDate) { errors.push({ name: 'endDate', message: t('fieldRequired')}) }

    return { errors, success: errors.length === 0 };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setErrors(errors.filter(err => err.name !== e.target.name));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const updateCategories = (selected: string[]) => {
    setFormData({ ...formData, categories: selected});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResult = validateData(formData);

    if (validationResult.success) {
      const result = isEdit ? await put(`/organization-goal/${formData.id}`, formData) : await post(`/organization-goal/${formData.id}`, { ...formData, scores });
      if (!result) {
        setErrors([{ name: 'form', message: t('serverError') }]);
      } else {
        if (isEdit) {
          window.location.reload();
        } else {
          navigate(`/organization/goal/${formData.id}`)
        }
      }
    } else {
      setErrors(validationResult.errors || []);
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth',
      }); 
    }
  };

  return (
    <Section>
      <H1>{isEdit ? t('editOrgHeading') : t('orgHeadingCreate')}</H1>
      <Box as="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      
        { errors.filter(e => e.name !== 'form').length > 0 && <ErrorText sx={{ mt: 32, fontSize: 24, }} text={t('formErrors')} /> }
        <ErrorText sx={{ mt: 32, fontSize: 24, }} error={errors.find(e => e.name === 'form')} />

        <Input
          error={errors.find(e => e.name === 'name')}
          name="name"
          handleChange={handleChange}
          label={t('name')}
          value={formData.name} />
          
        <Select
          options={[{ value: 'long-term', text: t('surveyTypeLongTerm') }, { value: 'workshop', text: t('surveyTypeWorkshop') }, { value: 'delivery', text: t('surveyTypeDelivery') }]}
          handleChange={handleChange}
          name="surveyType"
          label={t('surveyTypeLabel')}
          value={formData.surveyType}
          error={errors.find(e => e.name === 'surveyType')}
        />
        { scores && (<>
          <ImprovementSelector questions={getQuestionsForSurveyType(formData.surveyType)} handleChange={updateCategories} selected={formData.categories} scores={scores} /> 
          <Box as="p" sx={{ fontStyle: 'italic', fontSize: 14, textAlign: 'center', mt: 8 }}>{t('orgGoalCategoriesPeriod')}</Box>
          </>)
        }

        <Input
          error={errors.find(e => e.name === 'endDate')}
          type="date"
          name="endDate"
          handleChange={handleChange}
          label={t('endDate')}
          value={formData.endDate} />
        
        <Input
          type="textarea"
          name="goal"
          handleChange={handleChange}
          label={t('goal')}
          value={formData.goal}
        />

        <Input
          type="textarea"
          name="situation"
          handleChange={handleChange}
          label={t('situation')}
          value={formData.situation}
        />

        <Input
          type="textarea"
          name="plan"
          handleChange={handleChange}
          label={t('plan')}
          value={formData.plan}
        />

        <Button>{t(isEdit ? 'submitEdit' : 'submit')}</Button>
      </Box>
    </Section>
  );
};

export default OrgGoalForm;
