import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useApiData } from './ApiData';
import { GoalContext, ImprovementData, ImprovementGoal, ImprovementSurvey, Survey } from '../types';
import { scoreResult2ScoresObject } from '../lib/mapping';
import getQuestionsForSurveyType from '../lib/getQuestionsForSurveyType';

const ImprovementGoalContext = createContext<GoalContext>({ goal: null, originalSurvey: null, improvementData: null, recentSurveys: null, questions: [] });

type ImprovementGoalProviderProps = {
  children: ReactNode;
  goalId?: string;
  isShared?: boolean;
  surveyId?: string;
};

export const useImprovementGoal = () => {
  return useContext(ImprovementGoalContext);
};

export const ImprovementGoalProvider: React.FC<ImprovementGoalProviderProps> = ({ children, surveyId, goalId, isShared = false }) => {
  const { get } = useApiData();
  const [goal, setGoal] = useState<ImprovementGoal | null>(null);
  const [improvementData, setImprovementData] = useState<ImprovementData | null>(null);
  const [ recentSurveys, setRecentSurveys ] = useState<ImprovementSurvey[]>([]);
  const [ originalSurvey, setOriginalSurvey ] = useState<Survey | null>(null);
  const [ questions, setQuestions ] = useState<string[]>([]);

  useEffect(() => {
    const getImprovementData = async () => {
      if (!(surveyId || goal?.originalSurvey)) {
        return;
      }
      const result: ImprovementData = await get(`/survey/${surveyId || goal?.originalSurvey}/improvement-data`);
      if (!result) {
        return;
      }
      const { surveyName, answers, accessibleBy, startDate, endDate } = result;
      if (answers) {
        const scoreObj = scoreResult2ScoresObject(answers);
        setImprovementData({ surveyName, answers, accessibleBy, startDate, endDate, ...scoreObj });
      }
    }
    getImprovementData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, surveyId])

  useEffect(() => {
    if (!goal?.id) {
      return;
    }
    const getRecentSurvey = async () => {
      const result = await get(`/improvement-goal/${goal?.id}/surveys`);
      setRecentSurveys(result);
    }
    getRecentSurvey();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal])

  useEffect(() => {
    const sId = surveyId || goal?.originalSurvey;
    if (!sId) {
      return;
    }
    const getOriginalSurvey = async () => {
      const result = await get(`/survey/${sId}`);
      setOriginalSurvey(result);
      setQuestions(getQuestionsForSurveyType(result?.surveyType || 'long-term'));
    }
    getOriginalSurvey();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal])

  useEffect(() => {
    if (!goalId) {
      return;
    }
    const callOwn = async () => {
      const result = await get(`/improvement-goal/${goalId}`);
      if (result) {
        result.isShared = false;
        setGoal(result);
      }
    }
    const callShared = async () => {
      const result = await get(`/improvement-goal/${goalId}`);
      if (result) {
        result.isShared = true;
        setGoal(result);
      }
    }
    if (isShared) {
      callShared();
    } else {
      callOwn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId]);

  return (
    <ImprovementGoalContext.Provider value={{ goal, improvementData, recentSurveys, originalSurvey, questions }}>
      {children}
    </ImprovementGoalContext.Provider>
  );
};
